import styled from "styled-components";
import PropTypes, { InferProps } from "prop-types";

const StatusIndicatorproptypes = {
  children: PropTypes.node,
  status: PropTypes.oneOf(["pending", "failed", "success"]),
};

export type StatusIndicatorProps = InferProps<
  typeof StatusIndicatorproptypes
> & {
  status: "pending" | "failed" | "success";
  "data-testid"?: string;
};

export default function StatusIndicator({
  children,
  status,
  ...props
}: StatusIndicatorProps) {
  return (
    <_StatusIndicator status={status} {...props}>
      {children}
    </_StatusIndicator>
  );
}

const _StatusIndicator = styled.div<StatusIndicatorProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -7px;
  left: -7px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3);
  z-index: 1;
  background: ${(props) => {
    if (props.status === "pending") {
      return "linear-gradient(165deg, rgba(241,196,15,1) 0%, rgba(243,156,18,1) 100%)";
    }
    if (props.status === "success") {
      return "linear-gradient(165deg, rgba(168,206,59,1) 0%, rgba(86,156,56,1) 100%)";
    }
    return "linear-gradient(90deg, rgba(239,84,98,1) 0%, rgba(198,26,52,1) 100%)";
  }};

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    z-index: 0;
  }

  svg {
    color: ${(props) => props.theme.colors.WHITE};
    fill: #ffffff;
    width: ${(props) => {
      if (props.status === "pending") {
        return "16px";
      }
      if (props.status === "success") {
        return "14px";
      }
      return "10px";
    }};
    height: ${(props) => {
      if (props.status === "pending") {
        return "16px";
      }
      if (props.status === "success") {
        return "14px";
      }
      return "10px";
    }};
  }
`;
