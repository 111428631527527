import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { noop } from "../../../../services/noop";
import { useModal } from "../../../atoms/Modal/modal.provider";
import Notification, {
  NotificationProps,
  NotificationVariant,
} from "../../../atoms/Notification";
import ReportBetterModal from "../../../organisms/Modals/ReportBetterModal/ReportBetterModal";
import { Icon } from "../../Icon/Icon";
import { ReportBetterButton } from "./_ReportBetterNotification";
import { IconSize } from "../../Icon/IconSize";
import StatusIndicator from "../../../atoms/StatusIndicator";

export type ReportBetterNotificationProps = Partial<NotificationProps> & {
  givenName?: string;
  familyName?: string;
  pending: boolean;
  onReportBetter: () => void;
};

const ReportBetterNotification: FC<ReportBetterNotificationProps> = ({
  onReportBetter = noop,
  familyName,
  givenName,
  pending = false,
}) => {
  const modal = useModal();

  const handleReportBetter = () => {
    modal.add(
      ReportBetterModal,
      {
        familyName,
        givenName,
      },
      {
        onClose: (response) => {
          if (response?.done !== true) return;

          onReportBetter();
        },
      }
    );
  };

  return (
    <Notification.Base
      variant={NotificationVariant.ERROR}
      data-testid="report-better-notification"
    >
      {pending && (
        <StatusIndicator
          status="pending"
          data-testid="report-better-notification-pending"
        >
          <Icon size={IconSize.MD} name={"HourGlass"} />
        </StatusIndicator>
      )}
      <Notification.Wrapper>
        <Notification.Icon left hasBackground>
          <Icon name="SickFace" fill="#DE423C" size={IconSize.MD} />
        </Notification.Icon>
        <Notification.Content>
          <Notification.Title>
            <FormattedMessage id="reportBetterNotification.title" />
          </Notification.Title>
          <Notification.Text>
            <FormattedMessage id="reportBetterNotification.text" />{" "}
            <ReportBetterButton
              onClick={handleReportBetter}
              data-testid="report-better-notification-action"
            >
              <FormattedMessage id="reportBetterNotification.action" />
            </ReportBetterButton>
          </Notification.Text>
        </Notification.Content>
      </Notification.Wrapper>
    </Notification.Base>
  );
};

export default ReportBetterNotification;
