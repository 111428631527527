import React, { FC, useMemo, useState } from "react";
import { Role, useAuth } from "../../../../contexts/Auth/Auth";
import Modal, { ModalPropsType } from "../../../molecules/Modal/Modal";
import Button, { ButtonVariant } from "../../../atoms/Button";
import { Icon } from "../../../molecules/Icon/Icon";
import fetch from "../../../../services/fetch";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import throwAllErrors from "../../../../services/fetch/throwAllErrors";
import { useHeadsup } from "../../../atoms/Headsup/Headsup.provider";
import DefinitionList from "../../../molecules/DefinitionList/DefinitionList";
import { _InputDate, _NameContainer } from "./_ReportBetterModal";
import { IconSize } from "../../../molecules/Icon/IconSize";
import { useGetAbsenceStatusQuery } from "../../../../redux/apis/absence";
import { isToday } from "date-fns";
import Notification, {
  NotificationVariant,
} from "../../../../components/atoms/Notification";

export type ReportBetterModalProps = Partial<ModalPropsType> & {
  givenName?: string;
  familyName?: string;
};

const ReportBetterModal: FC<ReportBetterModalProps> = ({
  onRequestClose = () => {},
  givenName,
  familyName,
}) => {
  const messages = defineMessages({
    error: {
      id: "reportBetter.error",
      description:
        "Error while something went wrong when the user reports himself better",
    },
    formDateLabel: {
      id: "reportBetter.form.date.label",
    },
  });

  const { data: absenceStatus } = useGetAbsenceStatusQuery();

  const [reportBetterLoading, setReportBetterLoading] = useState(false);
  const [reportBetterDate, setReportBetterDate] = useState("");

  const auth = useAuth();

  const intl = useIntl();
  const { add } = useHeadsup();

  const getDateLimits = () => {
    let min;

    const max = new Date();
    max.setDate(max.getDate());

    if (absenceStatus?.since) {
      min = new Date(absenceStatus.since);
      min.setDate(min.getDate() + 1);
    } else {
      min = new Date();
      min.setDate(min.getDate() - 3);
    }

    const formatted = {
      min: min.toISOString().split("T")[0],
      max: max.toISOString().split("T")[0],
    };

    setReportBetterDate(formatted.max);

    return formatted;
  };

  const dateLimits = useMemo(() => getDateLimits(), []);

  const handleReportBetterDateChange = (e: any) => {
    const date = e.target.value;
    setReportBetterDate(date);
  };

  const handleRequestModalClose = (response?: any) => {
    if (reportBetterLoading) {
      return false;
    }

    onRequestClose(response);
  };

  const handleReportBetter = async (e: any) => {
    e.preventDefault();
    setReportBetterLoading(true);
    const formData = new FormData();
    formData.append("since", reportBetterDate);

    await fetch("report-better", {
      body: formData,
      method: "POST",
    })
      .then(throwAllErrors)
      .then(() => {
        onRequestClose({
          done: true,
        });
      })
      .catch(() => {
        setReportBetterLoading(false);

        add(intl.formatMessage(messages.error));
      });
  };

  const isAbsentSinceToday =
    absenceStatus?.isAbsent &&
    absenceStatus.since &&
    isToday(new Date(absenceStatus.since));

  const hasPendingStatusRequest = absenceStatus?.pending;

  return (
    <Modal.Base onRequestClose={onRequestClose}>
      <Modal.Icon>
        <Icon name="HappyFace" size={IconSize.XXL} fill="#4088FF" />
      </Modal.Icon>
      <Modal.Content>
        <Modal.Title>
          {auth.role === Role.IMPERSONATING_EMPLOYEE && (
            <FormattedMessage id="reportBetterModal.titleAsEmployee" />
          )}
          {auth.role === Role.EMPLOYEE && (
            <FormattedMessage id="reportBetterModal.titleAsHelpdesk" />
          )}
        </Modal.Title>
        <Modal.Text>
          <p>
            {auth.role === Role.EMPLOYEE && (
              <FormattedMessage id="reportBetterModal.description" />
            )}
          </p>
        </Modal.Text>

        {hasPendingStatusRequest && (
          <Notification.Base
            variant={NotificationVariant.PRIMARY}
            data-testid="report-better-pending-status-notification"
          >
            <Notification.Wrapper>
              <Notification.Icon left>
                <Icon name="InfoCircled" fill="white" size={IconSize.XS} />
              </Notification.Icon>
              <Notification.Content>
                <Notification.Text>
                  <FormattedMessage id="reportBetterModal.pendingStatusNotification" />{" "}
                </Notification.Text>
              </Notification.Content>
            </Notification.Wrapper>
          </Notification.Base>
        )}

        {!hasPendingStatusRequest && isAbsentSinceToday && (
          <Notification.Base
            variant={NotificationVariant.PRIMARY}
            data-testid="report-better-same-date-notification"
          >
            <Notification.Wrapper>
              <Notification.Icon left>
                <Icon name="InfoCircled" fill="white" size={IconSize.XS} />
              </Notification.Icon>
              <Notification.Content>
                <Notification.Text>
                  <FormattedMessage id="reportBetterModal.sameDayMessage" />{" "}
                </Notification.Text>
              </Notification.Content>
            </Notification.Wrapper>
          </Notification.Base>
        )}

        {!hasPendingStatusRequest && !isAbsentSinceToday && (
          <>
            <_NameContainer>
              {givenName && (
                <DefinitionList>
                  <dt>
                    <FormattedMessage defaultMessage="Voornaam" />
                  </dt>
                  <dd>{givenName}</dd>
                </DefinitionList>
              )}
              {familyName && (
                <DefinitionList>
                  <dt>
                    <FormattedMessage defaultMessage="Achternaam" />
                  </dt>
                  <dd>{familyName}</dd>
                </DefinitionList>
              )}
            </_NameContainer>
            <form onSubmit={handleReportBetter}>
              <_InputDate
                label={intl.formatMessage(messages.formDateLabel)}
                name="since"
                id="since"
                value={reportBetterDate}
                required={true}
                min={dateLimits.min}
                max={dateLimits.max}
                data-testid="report-better-date"
                onChange={handleReportBetterDateChange}
              />
              <Button
                textAlign="center"
                type="submit"
                isLoading={reportBetterLoading}
                data-testid="report-better"
                disabled={reportBetterDate === ""}
              >
                <FormattedMessage defaultMessage="Beter melden" />
              </Button>
              <Button
                variant={ButtonVariant.TRANSPARENT}
                textAlign="center"
                onClick={() => handleRequestModalClose()}
              >
                <FormattedMessage id="common.cancel" />
              </Button>
            </form>
          </>
        )}
      </Modal.Content>
    </Modal.Base>
  );
};

export default ReportBetterModal;
