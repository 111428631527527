import styled from "styled-components";

export const _AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const _AvatarWrapperInner = styled.div`
  position: absolute;
  display: block;
  width: 84px;
  height: 84px;
  border-radius: 42px;
  z-index: 0;
`;
