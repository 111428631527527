import styled from "styled-components";

const ICON_SIZE = 40;

export const _AvatarOnSickLeaveIcon = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  background: white;
  border-radius: ${ICON_SIZE / 2}px;
  display: grid;
  align-items: center;
  justify-items: center;

  svg {
    display: block;
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    transform: translateX(${ICON_SIZE * 0.03}px) scale(1.2);
    color: #de423c;
  }
`;
