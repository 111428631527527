import React, { FunctionComponent } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Card } from "./Card";
import styled from "styled-components";
import { useTheme } from "../../../hooks/useTheme";
import { CardProps, CardVariant } from "./Card.types";
import {
  _CardContent,
  _CardIcon,
  _CardText,
  _CardTitle,
  _CardWrapper,
} from "./_Card";

const CardSkeleton: FunctionComponent<CardProps & { isLoading?: boolean }> = ({
  variant = CardVariant.DEFAULT,
  ...props
}) => {
  const { theme } = useTheme();
  return (
    <SkeletonTheme
      baseColor={theme.colors.SKELETON_BASE}
      highlightColor={theme.colors.SKELETON_HIGHLIGHT}
    >
      <Card
        data-testid="card"
        href={props.href}
        action={props.action}
        variant={variant}
        dense={props.dense}
        {...props}
      >
        {props.isLoading ? (
          <_CardWrapper>
            <_CardIcon left>
              <Skeleton count={1} circle width={50} height={50}></Skeleton>
            </_CardIcon>
            <_CardContent>
              <_CardTitle>
                <_Skeleton count={1} width="60%" height={19} />
              </_CardTitle>
              <_CardText>
                <Skeleton count={1} height={10} />
              </_CardText>
            </_CardContent>
          </_CardWrapper>
        ) : (
          props.children
        )}
      </Card>
    </SkeletonTheme>
  );
};

export default CardSkeleton;

const _Skeleton = styled(Skeleton)`
  margin-bottom: 5px;
`;
