import styled from "styled-components";

const Input = styled.input`
  transition: all 200ms ease-in-out;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  outline: none;
  font-size: 16px;
  font-weight: ${(props) => (!props.readOnly ? "600" : "500")};
  color: #69696f;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:read-only {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

export default Input;
