import styled from "styled-components";

export const _DefinitionList = styled.dl`
  display: flex;
  flex-direction: column;
  margin: 0 30px 0 0;

  dt {
    font-size: 13px;
    color: ${(props) => props.theme.colors.BORDER};
    font-weight: 500;
  }

  dd {
    margin: 0;
    font-size: 17px;
    color: ${(props) => props.theme.colors.TEXT};
    font-weight: 700;
  }
`;

export const _DefinitionListWrapper = styled.div`
  display: flex;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d8d8d8;
`;
