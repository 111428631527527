import styled, { css } from "styled-components";

export const _Avatar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90px;
  height: 90px;
  border-radius: 45px;
  min-width: 90px;
`;

export const _AvatarRing = styled.div<{ variant?: Variant }>`
  width: 90px;
  height: 90px;
  border-radius: 45px;
  position: absolute;
  top: 0;
  left: 0;

  ${(props) => {
    switch (props.variant) {
      case "grey": {
        return css`
          background: rgb(153, 153, 153)
            linear-gradient(
              165deg,
              rgb(220, 220, 220) 0%,
              rgb(180, 180, 180) 20%,
              rgb(160, 160, 160) 40%,
              rgb(153, 153, 153) 60%,
              rgb(140, 140, 140) 80%,
              rgb(130, 130, 130) 100%
            );
        `;
      }
      case "error": {
        return css`
          background: rgb(222, 66, 60)
            linear-gradient(
              165deg,
              rgb(222, 66, 60) 0%,
              rgb(222, 66, 60) 20%,
              rgb(193, 58, 54) 40%,
              rgb(169, 45, 41) 60%,
              rgb(154, 37, 33) 80%,
              rgb(134, 25, 25) 100%
            );
        `;
      }
      case "primary": {
        return css`
          background: ${(props) => props.theme.colors.AVATAR};
        `;
      }
    }
  }};

  /* Mask was created using https://easy64.org/svg-editor/, using the following SVG
    <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <defs>
          <mask id="cut-off-bottom">
          <rect width="100" height="100" fill="#fff" />
          <circle cx="12" cy="12" r="11" fill="#000"/>
          </mask>
      </defs>
      <rect width="100" height="100" fill="#000" mask="url(#cut-off-bottom)" />
    </svg>
  */
  mask-image: url(data:image/svg+xml;base64,PHN2ZyByb2xlPSJpbWciIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZGVmcz4KICAgIDxtYXNrIGlkPSJjdXQtb2ZmLWJvdHRvbSI+CiAgICAgIDxyZWN0IHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjZmZmIiAvPgogICAgICA8Y2lyY2xlIGN4PSIxMiIgY3k9IjEyIiByPSIxMSIgZmlsbD0iIzAwMCIvPgogICAgPC9tYXNrPgogIDwvZGVmcz4KICA8cmVjdCB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsbD0iIzAwMCIgbWFzaz0idXJsKCNjdXQtb2ZmLWJvdHRvbSkiIC8+Cjwvc3ZnPg==);
`;

export type Variant = "primary" | "error" | "grey";
