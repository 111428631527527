import { useTheme } from "../../../hooks/useTheme";
import React from "react";
import { Ripple, RippleTransition } from "../Ripple/Ripple";
import { _Card, _CardInner } from "./_Card";
import { CardProps, CardVariant } from "./Card.types";

Card.defaultProps = {
  variant: CardVariant.DEFAULT,
};

export function Card({ action, children, variant, href, ...props }: CardProps) {
  const { theme } = useTheme();
  const enableRipple = action || href;

  return (
    <Ripple>
      <_Card variant={variant} onClick={action} href={href} {...props}>
        {enableRipple && (
          <RippleTransition
            color={`${
              variant === CardVariant.DEFAULT
                ? theme.colors.PRIMARY
                : theme.colors.WHITE
            }40`}
            centered={false}
          />
        )}
        <_CardInner>{children}</_CardInner>
      </_Card>
    </Ripple>
  );
}

export default Card;
