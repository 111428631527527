import {
  _Notification,
  _NotificationIcon,
  _NotificationText,
  _NotificationTextWrapper,
  _NotificationTitle,
  _NotificationWrapper,
} from "./_Notification";

export * from "./Notification.types";

export default {
  Base: _Notification,
  Title: _NotificationTitle,
  Text: _NotificationText,
  Icon: _NotificationIcon,
  Wrapper: _NotificationWrapper,
  Content: _NotificationTextWrapper,
};
