import styled from "styled-components";
import InputDate from "../../../molecules/InputDate/InputDate";
import { _DefinitionListWrapper } from "../../../molecules/DefinitionList/_DefinitionList";

export const _InputDate = styled(InputDate)`
  margin-bottom: 25px;
  max-width: 200px;
`;

export const _NameContainer = styled(_DefinitionListWrapper)`
  margin: 30px 0 20px;
`;
