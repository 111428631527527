import CardSkeleton from "./Card.Skeleton";

import {
  _CardContent,
  _CardIcon,
  _CardPeriod,
  _CardText,
  _CardTitle,
  _CardWrapper,
  _FullWidth,
  _CardInner,
} from "./_Card";

export * from "./Card.types";

export default {
  Base: CardSkeleton,
  Wrapper: _CardWrapper,
  Content: _CardContent,
  Title: _CardTitle,
  Text: _CardText,
  Icon: _CardIcon,
  Period: _CardPeriod,
  FullWidth: _FullWidth,
  Inner: _CardInner,
};
