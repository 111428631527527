import { connect } from "react-redux";
import { RootDispatch, RootState } from "../../../../redux";
import ReportBetterNotification, {
  ReportBetterNotificationProps,
} from "./ReportBetterNotification";
import { emptySplitApi } from "../../../../redux/apis";

function mapStateToProps(
  state: RootState
): Pick<ReportBetterNotificationProps, "givenName" | "familyName"> {
  return {
    givenName: state.userMeta.user?.givenName || "\u00A0",
    familyName: state.userMeta.user?.familyName || "\u00A0",
  };
}

function mapDispatchToProps(dispatch: RootDispatch) {
  return {
    onReportBetter: () => {
      return dispatch(emptySplitApi.util.invalidateTags(["Absence"]));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportBetterNotification);
