import React, { FC, HTMLProps, ReactNode } from "react";
import { _Input, _InputDateWrapper, _InputLabel } from "./_InputDate";

export type InputDateProps = Partial<HTMLProps<HTMLInputElement>> & {
  label: ReactNode | String;
};

// ignore* workaround for styledcomponents
export const InputDate: FC<InputDateProps> = ({
  ref: ignoreRef,
  as: ignoreAs,
  label,
  id,
  ...props
}) => {
  return (
    <_InputDateWrapper>
      <_InputLabel htmlFor={id}>{label}</_InputLabel>
      <_Input type="date" id={id} {...props} />
    </_InputDateWrapper>
  );
};

export default InputDate;
