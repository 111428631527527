import styled from "styled-components";
import Input from "../../atoms/Input";

export const _InputDateWrapper = styled.div`
  position: relative;
`;

export const _InputLabel = styled.label`
  position: absolute;
  top: 5px;
  left: 15px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.BORDER};
`;

export const _Input = styled(Input)`
  padding: 25px 15px 10px 15px;
  border-radius: 6px;
  border: 1px solid #cecece;
  font-size: 17px;
  color: ${(props) => props.theme.colors.TEXT};
  font-family: ${(props) => props.theme.text.fontFamily};

  &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
  }
`;
