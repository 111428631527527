import React, { ComponentType, FC } from "react";
import Router from "next/router";
import { Role, useAuth } from "../contexts/Auth/Auth";

export const withRole =
  <P extends object>(Component: ComponentType<P>, roles: Role[]): FC<P> =>
  (props) => {
    const auth = useAuth();

    // Don't return any component, until loading has been completed.
    if (auth.isLoading) {
      return null;
    }

    // If the roles for the page doesn't include the user role, redirect the user to the correct page;
    if (!roles.includes(auth.role)) {
      switch (auth.role) {
        case Role.UNAUTHENTICATED:
          Router.push("/inloggen");
          return null;
        case Role.EMPLOYEE:
        case Role.IMPERSONATING_EMPLOYEE:
          Router.push("/");
          return null;
        case Role.HELPDESK:
          Router.push("/helpdesk");
          return null;
        default:
          throw new Error(`Unknown role: ${auth.role}`);
      }
    }

    return <Component {...props} />;
  };

export default withRole;
