import styled, { css } from "styled-components";
import { _RippleTransition } from "../Ripple/RippleTransition";
import { NotificationVariant } from "./Notification.types";
import { LinkOrButton } from "../LinkOrButton/LinkOrButton";

export const _NotificationTitle = styled.div`
  font-size: 16px !important;
  font-weight: 700;
`;

export const _NotificationText = styled.div`
  font-weight: 500;
`;

export const _Notification = styled(LinkOrButton)<{
  variant: NotificationVariant;
  onClick?: () => void;
}>`
  display: flex;
  border-radius: 14px;
  background-color: ${(props) =>
    props.theme.Notification.Variants[props.variant].backgroundColor};
  margin-bottom: 10px;
  padding: 15px;
  position: relative;
  box-shadow: ${(props) => props.theme.shadows[0]};
  cursor: ${(props) =>
    typeof props.onClick === "function" ? "pointer" : "default"};
  border: 1px solid rgba(255, 255, 255, 0.25);
  -webkit-tap-highlight-color: transparent;

  a {
    text-decoration: none;
  }

  ${_RippleTransition} {
    border-radius: 14px;
  }

  ${_NotificationTitle} {
    color: ${(props) =>
      props.theme.Notification.Variants[props.variant].textColor};
  }

  ${_NotificationText} {
    color: ${(props) =>
      props.theme.Notification.Variants[props.variant].textColor};
  }
`;

export const _NotificationInner = styled.div``;

export const _NotificationTextWrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;

  a {
    color: currentColor;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`;

export const _NotificationIcon = styled.div<{
  left?: boolean;
  right?: boolean;
  hasBackground?: boolean;
}>`
  flex: 0 0 auto;

  ${({ hasBackground }) => {
    if (hasBackground) {
      return css`
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 48px;
        height: 48px;
        border-radius: 16px;
      `;
    }
  }}
  ${({ left }) => {
    if (left) {
      return css`
        margin-right: 10px;
      `;
    }
  }}
  ${({ right }) => {
    if (right) {
      return css`
        margin-left: 10px;
      `;
    }
  }}
`;

export const _NotificationWrapper = styled.div`
  display: flex;
  width: 100%;
`;
