import React, { FunctionComponent } from "react";
import FocusTrap from "focus-trap-react";
import {
  _Backdrop,
  _CloseButton,
  _Modal,
  _ModalContainer,
  _ModalContent,
  _ModalIcon,
  _ModalText,
  _ModalTitle,
} from "./_Modal";
import {
  CloseButtonSize,
  CloseButtonVariant,
} from "../../atoms/CloseButton/CloneButton.types";
import { IconSize } from "../Icon/IconSize";

export type ModalPropsType = {
  onRequestClose: (response?: any) => void;
  "data-testid"?: string;
};

const Base: FunctionComponent<ModalPropsType> = ({
  children,
  onRequestClose = () => {},
  ...props
}) => {
  /*
   * The FocusTrap closes by default when pressing escape. Our modal however does not.
   * Ideally this would be done listening to the onDeactivate event for the FocusTrap,
   * but this causes issues in DEV mode (Strict Mode) as the component gets mounted twice,
   * triggering the onDeactivate event and thus immediately closing our modal. Therefore
   * we have an additional listener for the escape key on the Modal component itself.
   *
   * See the link below for more details.
   *
   * https://github.com/focus-trap/focus-trap-react/issues/796
   */

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "Escape") {
      onRequestClose();
    }
  };

  return (
    <FocusTrap>
      <_ModalContainer
        data-testid={props["data-testid"] ? props["data-testid"] : "modal"}
        onKeyDown={onKeyDown}
      >
        <_Modal>
          <_CloseButton
            size={CloseButtonSize.SMALL}
            variant={CloseButtonVariant.RED}
            iconSize={IconSize.SM}
            onClick={() => onRequestClose()}
            data-testid="modal-close"
          />
          {children}
        </_Modal>
        <_Backdrop
          onClick={() => onRequestClose()}
          data-testid="modal-backdrop"
        />
      </_ModalContainer>
    </FocusTrap>
  );
};

export default {
  Base,
  Icon: _ModalIcon,
  Content: _ModalContent,
  Title: _ModalTitle,
  Text: _ModalText,
};
