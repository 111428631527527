import styled, { css } from "styled-components";
import { LinkOrButton } from "../LinkOrButton/LinkOrButton";
import { _RippleTransition } from "../Ripple/Ripple";

export const _CardTitle = styled.div`
  display: block;
  font-size: 17px;
  font-weight: 700;

  small {
    font-weight: 500;
    font-size: 14px;
  }

  strong {
    font-size: 19px;
  }
`;

export const _CardText = styled.div`
  display: block;
  font-size: 14px;
  font-weight: 400;

  strong {
    text-transform: lowercase;
    font-weight: 600;
  }
`;

export const _CardIcon = styled.div<{ left?: boolean; right?: boolean }>`
  display: flex;
  flex: 0 0 auto;
  gap: 15px;

  ${({ left }) => {
    if (left) {
      return css`
        margin-right: 10px;
      `;
    }
  }}
  ${({ right }) => {
    if (right) {
      return css`
        margin-left: 10px;
      `;
    }
  }}
`;

export const _CardInner = styled.div<{ dense?: boolean }>`
  position: relative;
  padding: 15px;
  height: 100%;
`;

export const _FullWidth = styled.div`
  margin: 0 -15px;
`;

export const _Card = styled(LinkOrButton)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  position: relative;
  border-radius: 14px;
  background-color: ${(props) =>
    props.theme.card.variants[props.variant].backgroundColor};
  margin: 0 0 10px;
  box-shadow: ${(props) => props.theme.shadows[0]};
  cursor: ${({ onClick, href }) => (onClick || href ? "pointer" : "default")};
  border: 1px solid rgba(255, 255, 255, 0.25);
  min-height: 69px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  text-align: left;
  padding: 0;

  a {
    color: ${(props) => props.theme.card.variants[props.variant].textColor};
    text-decoration: none;
  }

  ${_RippleTransition} {
    border-radius: 14px;
  }

  ${_CardTitle},
  ${_CardText} {
    color: ${(props) => props.theme.card.variants[props.variant].textColor};

    small {
      color: ${(props) =>
        props.theme.card.variants[props.variant].textColorLight};
    }

    strong {
      color: ${(props) => props.theme.card.variants[props.variant].textColor};
    }
  }

  ${_CardIcon} {
    color: ${(props) => props.theme.card.variants[props.variant].iconColor};
  }

  ${_CardInner} {
    width: 100%;
    ${({ dense }) => {
      if (dense) {
        return css`
          padding: 5px 10px;
        `;
      }
    }}
  }

  ${_FullWidth} {
    ${({ dense }) => {
      if (dense) {
        return css`
          margin: 0 -10px;
        `;
      }
    }}
  }
`;

export const _CardPeriod = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #5c5c5c;
`;

export const _CardWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const _CardContent = styled.div`
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
`;
