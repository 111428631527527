import styled from "styled-components";

export const _AvatarHolder = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.55);
  z-index: 1;
  overflow: hidden;

  img {
    width: 76px;
    height: 76px;
    border-radius: 50%;
  }

  .react-loading-skeleton {
    transform: translateY(-1px);
  }
`;
