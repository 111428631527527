import { connect } from "react-redux";
import { RootState } from "../../../redux";
import { AvatarProps } from "./Avatar.types";
import Avatar from "./Avatar";

function mapStateToProps(
  state: RootState
): Pick<AvatarProps, "email" | "profilePhotoUrl" | "isLoading"> {
  return {
    email: state.userMeta.user?.email || "",
    profilePhotoUrl: state.userMeta.user?.profilePhotoUrl,
    isLoading: state.userMeta.isLoading,
  };
}

export default connect(mapStateToProps)(Avatar);
