import styled from "styled-components";
import CloseButton from "../../atoms/CloseButton/CloseButton";

export const _Backdrop = styled.div`
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(2px);
  z-index: 4;
`;

export const _ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const _Modal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  width: calc(100% - 30px);
  max-width: 546px;
  z-index: 5;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows[0]};
`;

export const _ModalTitle = styled.h3`
  margin: 0 0 15px 0;
  font-size: 18px;
  text-align: center;
  color: ${(props) => props.theme.colors.TEXT};
`;

export const _ModalText = styled.div`
  margin: 0 0 15px 0;
  font-size: 16px;
  text-align: center;
  color: ${(props) => props.theme.colors.TEXT_LIGHT};
`;

export const _ModalContent = styled.div`
  position: relative;
  padding: 15px;
`;

export const _ModalIcon = styled.div`
  position: absolute;
  top: -46px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 94px;

  &:before {
    position: absolute;
    top: 0;
    content: "";
    width: 100%;
    height: 50%;
    border-radius: 100px 100px 0 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: -1;
  }

  ~ ${_ModalContent} {
    padding-top: 50px;
  }
`;

export const _CloseButton = styled(CloseButton)`
  position: absolute;
  top: -12px;
  right: -12px;
`;
