import { Icon } from "../Icon/Icon";
import { AvatarProps } from "./Avatar.types";
import { _Avatar, _AvatarRing } from "./_Avatar";
import { _AvatarWrapper, _AvatarWrapperInner } from "./_AvatarWrapper";
import { _AvatarHolder } from "./_AvatarHolder";
import { _AvatarOnSickLeaveIcon } from "./_AvatarOnSickLeaveIcon";
import ProfilePicture from "../../atoms/ProfilePicture";

function Avatar({
  onSickLeave,
  isLoading,
  email,
  profilePhotoUrl,
}: AvatarProps) {
  return (
    <_Avatar data-test-id="avatar">
      <_AvatarRing
        variant={isLoading ? "grey" : onSickLeave ? "error" : "primary"}
        data-test-id="avatar-ring"
      />
      <_AvatarWrapper>
        <_AvatarWrapperInner data-test-id="avatar-wrapper-inner" />
        <_AvatarHolder>
          <ProfilePicture
            email={email}
            src={profilePhotoUrl}
            isLoading={isLoading}
          />
        </_AvatarHolder>
      </_AvatarWrapper>
      {onSickLeave && (
        <_AvatarOnSickLeaveIcon data-test-id="avatar-sick-leave-icon">
          <Icon name="SickFace" />
        </_AvatarOnSickLeaveIcon>
      )}
    </_Avatar>
  );
}

export default Avatar;
