import styled from "styled-components";

export const ReportBetterButton = styled.button`
  text-decoration: underline;
  display: inline;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: transparent;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
  line-height: inherit;
`;
