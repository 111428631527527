import Image from "next/image";
import Gravatar from "react-gravatar";
import Skeleton from "react-loading-skeleton";
import { ProfilePictureProps } from "./ProfilePicture.types";
import { _ProfilePictureFallback } from "./_ProfilePicture";

function ProfilePicture({
  src,
  email,
  isLoading,
  size = 76,
  shape = "circle",
}: ProfilePictureProps) {
  if (isLoading) {
    if (shape === "square") {
      return <Skeleton count={1} width={size} height={size} />;
    }
    if (shape === "circle") {
      return <Skeleton count={1} width={size} height={size} circle />;
    }
  }

  if (src) {
    return (
      <Image
        src={src}
        width={size}
        height={size}
        data-test-id="ProfilePicture__image"
      />
    );
  }

  if (email) {
    return (
      <Gravatar
        email={email}
        default="identicon"
        size={size}
        data-test-id="ProfilePicture__gravatar"
      />
    );
  }

  return (
    <_ProfilePictureFallback
      size={size}
      data-test-id="ProfilePicture__fallback"
    />
  );
}

export default ProfilePicture;
